import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createTicket, reset } from "../features/tickets/ticketSlice";
import Spinner from "../components/Spinner";
import BackButton from "../components/BackButton";

function NewTicket() {
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.tickets
    );
    
    const [name] = useState(user?.name);
    const [email] = useState(user?.email);
    const [project, setProject] = useState("");
    const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");
  const [isOnPriority, setIsOnPriority] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess) {
      dispatch(reset());
      navigate("/tickets");
    }
  }, [dispatch, isError, isSuccess, navigate, message]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createTicket({ project, description, isOnPriority, dueDate }));
  };

  const handleDueDate = (e) => {
    setDueDate(e.target.value);
  }

  if (isLoading) return <Spinner />;

  return (
    <>
      <BackButton url="/" />
      <section className="heading">
        <h1>Create New Ticket</h1>
        <p>Please fill out the form below</p>
      </section>

      <section className="form">
        <div className="form-group">
          <label htmlFor="name">Your Name</label>
          <input type="text" className="form-control" value={name} disabled />
        </div>
      </section>

      <section className="form">
        <div className="form-group">
          <label htmlFor="name">Your Email</label>
          <input type="text" className="form-control" value={email} disabled />
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="project">Project Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Project Name"
              value={project}
              onChange={(e) => setProject(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description of the issue</label>
            <textarea
              className="form-control"
              placeholder="Description"
              value={description}
              name="description"
              id="description"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="is-on-priority">
            <div>
              <label htmlFor="priority">
                Due Date
                <input type="date" value={dueDate} onChange={handleDueDate} />
              </label>
            </div>
            <div>
              <label htmlFor="description">Is it on priority?</label>
              <label className="ticket-label" for="yes">
                <input
                  type="radio"
                  id="yes"
                  name="ticket-label"
                  value="yes"
                  checked={isOnPriority}
                  onChange={() => setIsOnPriority(true)}
                />
                Yes
              </label>
              <label className="ticket-label" for="no">
                <input
                  type="radio"
                  id="no"
                  name="ticket-label"
                  value="no"
                  checked={!isOnPriority}
                  onChange={() => setIsOnPriority(false)}
                />{" "}
                No
              </label>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-block">Submit</button>
          </div>
        </form>
      </section>
    </>
  );
}

export default NewTicket;
