import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTicket, getTickets } from "../features/tickets/ticketSlice";
import { Autocomplete, Backdrop, Box, Modal, TextField } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const CreateTicketModal = ({ openTicketModal, setOpenTicketModal }) => {

  const { departments } = useSelector((state) => state.departments);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    project: Yup.string()
      .min(3, "Project name must be at least 3 characters")
      .max(200, "Project name must be at most 200 characters")
      .required("Project name is required"),
    description: Yup.string()
      .min(3, "Description must be at least 3 characters")
      .required("Description is required"),
    department: Yup.string().required("Department is required"),
    dueDate: Yup.date()
      .min(new Date().toISOString().split('T')[0], "Due date cannot be in the past") // Allow today's date
      .nullable()
  });

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createTicket(values)).then(() => {
      dispatch(getTickets({ isClosedTickets: false }));
      toast.success("Ticket created successfully")
      handleClose();
    });
    setSubmitting(false);
  };

  const handleClose = () => {
    setOpenTicketModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    boxShadow: 24,
    maxHeight: "92vh",
    overflowY: "auto",
    p: 2,
    borderRadius: 2,
  };

  return (
    <Modal
      open={openTicketModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="mt-1">
          <h3 id="modal-modal-title" className="mb-4">
            Create New Ticket
          </h3>

          <section className="w-auto">
            <Formik
              initialValues={{
                project: "",
                description: "",
                isOnPriority: false,
                department: "",
                dueDate: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-group mb-2">
                    <label htmlFor="project">
                      Project Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className={`focus:outline-none form-control ${errors.project && touched.project
                        ? "!border-red-500"
                        : "border-gray-300"
                        }`}
                      placeholder="Project Name"
                      name="project"
                      value={values.project}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      minLength={3}
                      maxLength={200}
                    />
                    <ErrorMessage
                      name="project"
                      component="div"
                      className="text-red-500 mt-[-11px]"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="description">
                      Description of the issue
                      <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      className={`focus:outline-none form-control ${errors.description && touched.description
                        ? "!border-red-500"
                        : "border-gray-300"
                        }`}
                      placeholder="Description"
                      value={values.description}
                      name="description"
                      id="description"
                      rows="4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500 mt-[-11px]"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="project">
                      Department
                      <span className="text-red-500">*</span>
                    </label>
                    <Autocomplete
                      options={departments}
                      getOptionLabel={(option) => option?.name}
                      onChange={(e, value) => {
                        handleChange(e);
                        if (value) {
                          setFieldValue("department", value._id);
                        } else {
                          setFieldValue("department", "");
                        }
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // variant="outlined"
                          placeholder="select department"
                          error={errors.department && touched.department}
                        // helperText={errors.department && touched.department && errors.department}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="department"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="priority">Due Date</label>
                    <input
                      type="date"
                      name="dueDate"
                      value={values.dueDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`focus:outline-none form-control ${errors.dueDate && touched.dueDate
                        ? "border-red-500"
                        : ""
                        }`}
                    />
                    <ErrorMessage
                      name="dueDate"
                      component="div"
                      className="text-red-500 mt-[-11px]"
                    />
                  </div>
                  <div className="mb-8 ml-px">
                    <div className="mb-3">Is it on High priority</div>
                    <div className="flex">
                      <label className="ticket-label mr-4" for="yes">
                        <input
                          type="radio"
                          id="yes"
                          name="isOnPriority"
                          value="yes"
                          checked={values.isOnPriority}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="pl-0.25"> Yes</span>
                      </label>
                      <label className="ticket-label" for="no">
                        <input
                          type="radio"
                          id="no"
                          name="isOnPriority"
                          value="no"
                          checked={!values.isOnPriority}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />{" "}
                        <span className="pl-0.25">No </span>
                      </label>
                    </div>
                    <ErrorMessage
                      name="isOnPriority"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex justify-between">
                    <button
                      className="btn mb-4 border border-[#d0d5dd] text-primary w-40 h-10"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn mb-4 bg-primary text-white w-40 h-10"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateTicketModal;
