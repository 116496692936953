import { Box, Modal } from "@mui/material";
import React from "react";

const ConfirmDeleteModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  handleDelete,
  setSelectData
}) => {
  const handleClose = () => {
    setOpenDeleteModal(false);
    setSelectData({name: '', id: ''})
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 315,
    bgcolor: "background.paper",
    boxShadow: 24,
    height: "100",
    overflowY: "auto",
    p: 4,
    borderRadius: 2,
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleDelete();
    setOpenDeleteModal(false);
  };

  return (
    <Modal open={openDeleteModal} onClose={handleClose}>
      <Box sx={style}>
        <form onSubmit={onSubmit}>
          <h3 className="text-center text-red-600 mb-8">
            Are you sure you want to delete this?
          </h3>
          <div className="flex justify-between">
            <button
              className="btn mb-4 border border-[#d0d5dd] text-primary w-28 h-10"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn mb-4 bg-primary text-white  h-10"
            >
              Yes, Delete!
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteModal;
