import { useEffect, useState } from 'react';

const useInactivityTimer = (timeoutDuration, onTimeout) => {
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        let timeout;

        const handleActivity = () => {
            setIsActive(true);
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setIsActive(false);
                onTimeout();
            }, timeoutDuration);
        };

        // Set up event listeners for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        // Start the timer on mount
        timeout = setTimeout(() => {
            setIsActive(false);
            onTimeout();
        }, timeoutDuration);

        // Clean up event listeners and timeout on unmount
        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            clearTimeout(timeout);
        };
    }, [timeoutDuration, onTimeout]);

    return isActive;
};

export default useInactivityTimer;
