import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../components/BackButton";
import { toast } from "react-toastify";
import { reset, resetPassword } from "../features/auth/authSlice";
import { FaRegEdit } from "react-icons/fa";
import Spinner from "../components/Spinner";

const UserProfile = () => {
  const { user, isSuccess, isError, isLoading, message } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const inputNameRef = useRef(null);
  const inputPasswordRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    password2: "",
  });
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);

  const { name, password, password2 } = formData; // destructuring

  useEffect(() => {
    if (isNameEdit) {
      setFormData({ ...formData, name: user?.name ?? "" });
    }
  }, [user, isNameEdit]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message.message);
      dispatch(reset());
    } else if (isError) {
      toast.error("Unable to reset password");
    }
  }, [isSuccess, isError, message]);

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: user?.email,
    };

    if (password) {
      if (password !== password2) {
        toast.error("Passwords do not match");
      } else {
        userData.password = password;
      }
    }

    if (name) {
      userData.name = name;
    }

    dispatch(resetPassword(userData));
    setFormData({
      name: "",
      password: "",
      password2: "",
    });
    setIsNameEdit(false);
    setIsPasswordEdit(false);
  };

  const onChange = (e) => {
    if (e.target.name === "name") {
      setFormData((prevState) => ({
        ...prevState,
        name: e.target.value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    if (isNameEdit) {
      inputNameRef.current.focus();
    }
  }, [isNameEdit]);

  useEffect(() => {
    if (isPasswordEdit) {
      inputPasswordRef.current.focus();
    }
  }, [isPasswordEdit]);

  const handleEdit = (val) => {
    if (val === "name") {
      setIsNameEdit((prev) => !prev);
    }
    if (val === "password") {
      setIsPasswordEdit((prev) => !prev);
    }

    if (isNameEdit || isPasswordEdit) {
      setFormData({
        ...formData,
        [val]: "",
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section className="heading">
        <h1>User Profile</h1>
      </section>
      <section className="form">
        <form onSubmit={onSubmit}>
          <div className="form-group relative">
            <label htmlFor="name">Your Name</label>
            <div className="input-wrapper">
              <input
                type="text"
                name="name"
                className="form-control  disabled:bg-[#e9e9e9]"
                value={isNameEdit ? name : user?.name}
                onChange={onChange}
                disabled={!isNameEdit}
                maxLength="40"
                ref={inputNameRef}
              />
              <span
                className="edit-icon absolute right-2 bottom-[23.5px]"
                onClick={() => handleEdit("name")}
              >
                <FaRegEdit className="text-lg" />
              </span>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="name">Your Email</label>
            <input
              type="text"
              name="email"
              className="form-control bg-[#e9e9e9]"
              value={user?.email}
              disabled
            />
          </div>

          <div className="form-group relative">
            <label htmlFor="project">Change Password</label>
            <div className="input-wrapper">
              <input
                type="password"
                id="password"
                name="password"
                value={isPasswordEdit ? password : "********"}
                onChange={onChange}
                placeholder="change your password"
                className="form-control  disabled:bg-[#e9e9e9]"
                disabled={!isPasswordEdit}
                maxLength="30"
                ref={inputPasswordRef}
              />
              <span
                className="edit-icon absolute right-2 bottom-[23.5px]"
                onClick={() => handleEdit("password")}
              >
                <FaRegEdit className="text-lg" />
              </span>
            </div>
          </div>
          {password?.length ? (
            <div className="form-group">
              <label htmlFor="description">Confirm changed Password</label>
              <input
                type="password"
                id="password2"
                name="password2"
                value={password2}
                onChange={onChange}
                placeholder="Confirm your password"
                className="form-control"
                maxLength="30"
                required
              />
            </div>
          ) : null}
          {password2?.length || (name?.length && name !== user?.name) ? (
            <div className="form-group">
              <button
                className="btn mb-4 bg-primary text-white ml-auto"
                type="submit"
              >
                Update Changes
              </button>
            </div>
          ) : null}
        </form>
      </section>
    </>
  );
};

export default UserProfile;
