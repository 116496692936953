import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash, FaSignInAlt, FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { register, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
import { Box, Card, Container } from "@mui/material";
import logo from "../assets/dmllogo.svg";
import loginImg from "../assets/persononcall.png";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePassword2Visibility = () => {
    setShowPassword2(!showPassword2);
  };

  /**
   * useDispatch() returns a reference to the dispatch function
   * from the Redux store. You may use it to dispatch actions
   * as needed.
   */

  /**
   * By using 'useSelector' and 'useDispatch' from 'react-redux',
   * we can read state from a Redux store and dispatch any
   * action from a component, respectively.
   */
  const dispatch = useDispatch();

  const navigate = useNavigate();

  /**
   * useSelector() allows you to extract data from the Redux store state,
   * using a selector function.
   */
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    // Redirect when logged in
    if (isSuccess || user) {
      navigate("/tickets");
    }

    dispatch(reset());
  }, [isError, isSuccess, user, navigate, message, dispatch]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets and spaces")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@digimantra\.com$/,
        "Email must be a valid digimantra.com address"
      )
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, one special character, and no spaces"
      )
      .required("Password is required"),
    password2: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Password and confirm password must match"
      )
      .required("Confirm Password is required"),
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen">
      <div className="w-1/2 h-full">
        <img src={loginImg} alt="img" className="w-full h-full object-cover" />
      </div>
      <div className="flex text-center w-1/2">
        <Container
          component="main"
          maxWidth="xs"
          sx={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div className="flex justify-center mb-10 items-center">
            <img className={`w-[2.5rem] h-[2.5rem]`} src={logo} alt="logo" />
            <span className="header-text"> Ticketing</span>
          </div>
          <Card variant="outlined" className="auth-card">
            <section className="heading">
              <h1 className="text-xl">Register now!</h1>
              <p className="text-base text-grey">Please create an account</p>
            </section>
            <Formik
              initialValues={{
                role: "user",
                name: "",
                email: "",
                password: "",
                password2: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                if (values.password !== values.password2) {
                  toast.error("Passwords do not match");
                } else {
                  const userData = {
                    role: values.role,
                    name: values.name,
                    email: values.email,
                    password: values.password,
                  };
                  dispatch(register(userData));
                  toast.success("User registered successfully");
                }
                setSubmitting(false);
              }}
            >
              {({
                isValid,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
              }) => (
                <Form>
                  <Box sx={{ mt: 3 }}>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        className={`form-control ${
                          errors.name && touched.name ? "!border-red-500" : ""
                        }`}
                        maxLength="40"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500 pl-0.5 mt-[-10px] !text-xs text-left"
                      />
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        className={`form-control ${
                          errors.email && touched.email ? "!border-red-500" : ""
                        }`}
                        maxLength="50"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 pl-0.5 mt-[-10px] !text-xs text-left"
                      />
                    </div>
                    <div className="form-group mb-2 relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        className={`form-control ${
                          errors.password && touched.password
                            ? "!border-red-500"
                            : ""
                        }`}
                        maxLength="30"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                      />
                      <span
                        type="span"
                        className="absolute top-[0.68rem] right-2 focus:outline-none cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FaEye className="h-5 w-5 text-gray-400" />
                        ) : (
                          <FaEyeSlash className="h-5 w-5 text-gray-400" />
                        )}
                      </span>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 pl-0.5 mt-[-10px] !text-xs text-left"
                      />
                    </div>
                    <div className="form-group mb-2 relative">
                      <input
                        type={showPassword2 ? "text" : "password"}
                        id="password2"
                        name="password2"
                        placeholder="Confirm your password"
                        className={`form-control relative ${
                          errors.password2 && touched.password2
                            ? "!border-red-500"
                            : ""
                        }`}
                        maxLength="30"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password2}
                      />
                      <span
                        type="span"
                        className="absolute top-[0.68rem] right-2 focus:outline-none cursor-pointer"
                        onClick={togglePassword2Visibility}
                      >
                        {showPassword2 ? (
                          <FaEye className="h-5 w-5 text-gray-400" />
                        ) : (
                          <FaEyeSlash className="h-5 w-5 text-gray-400" />
                        )}
                      </span>
                      <ErrorMessage
                        name="password2"
                        component="div"
                        className="text-red-500 pl-0.5 mt-[-10px] !text-xs text-left"
                      />
                    </div>
                    <div className="form-group mb-2">
                      <button
                        className={`btn btn-block ${
                          !isValid || !dirty || isSubmitting
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-primary hover:bg-primary-dark"
                        } text-white`}
                        type="submit"
                        disabled={!isValid || !dirty || isSubmitting}
                      >
                        {isSubmitting ? <Spinner /> : "Continue"}
                      </button>
                    </div>
                    <p>
                      Do you already have an account?{" "}
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => navigate("/login")}
                      >
                        Sign In
                      </span>
                    </p>
                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default Register;
