import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import managerService from "./managerService";
import { toast } from "react-toastify";

const initialState = {
  managers: [],
  manager: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
const token = localStorage.getItem("token");


export const createManager = createAsyncThunk(
  "managers/create",
  async (managerData, thunkAPI) => {
    try {
      return await managerService.createManager(managerData, token);
    } catch (err) {
      const message = err?.response?.data?.message || err?.message || err?.toString();

      console.log("Error occurred while creating manager:", message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllManagers = createAsyncThunk(
  "managers/getAll",
  async (_, thunkAPI) => {
    try {
      return await managerService.getAllManagers(token);
    } catch (err) {
      const message = err?.response?.data?.message || err?.message || err?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getManager = createAsyncThunk(
  "managers/get",
  async (id, thunkAPI) => {
    try {
      return await managerService.getManager(id, token);
    } catch (err) {
      const message = err?.response?.data?.message || err?.message || err?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateManager = createAsyncThunk(
  "managers/update",
  async (managerData, thunkAPI) =>
  {
    return await managerService.updateManager(managerData, token);
  }
);

export const deleteManager = createAsyncThunk(
  "managers/delete",
  async (id, thunkAPI) => {
    try {

      return await managerService.deleteManager(id, token);
    } catch (err) {
      const message = err?.response?.data?.message || err?.message || err?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllManagers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllManagers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.managers = action.payload;
      })
      .addCase(getAllManagers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.managers.push(action.payload);
      })
      .addCase(createManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload)
      })
      .addCase(deleteManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteManager.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload)
      })
      .addCase(getManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manager = action.payload;
      })
      .addCase(getManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manager = action.payload;
      })
      .addCase(updateManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload)
      });
  },
});

export default managerSlice.reducer;
