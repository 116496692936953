import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import ticketReducer from '../features/tickets/ticketSlice'
import noteReducer from '../features/notes/noteSlice'
import managerReducer from '../features/manager/managerSlice'
import departmentReducer from '../features/department/departmentSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    tickets: ticketReducer,
    notes: noteReducer,
    managers: managerReducer,
    departments: departmentReducer,
  }
})
