import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/departments/`;

const getAllDepartments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  return response.data;
};

const getDepartment = async (departmentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + departmentId, config);
  return response.data;
};

const createDepartment = async (departmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, departmentData, config);
  return response.data;
};

const updateDepartment = async (departmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL + departmentData?.id,
    departmentData?.data,
    config
  );
  return response.data;
};

const deleteDepartment = async (departmentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + departmentId, config);
  return response.data;
};

const departmentService = {
  getAllDepartments,
  getDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
};

export default departmentService;
