import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Backdrop, Box, Modal, TextField } from "@mui/material";
import {
  createManager,
  getAllManagers,
  getManager,
  updateManager,
} from "../features/manager/managerSlice";
import { getAllDepartments } from "../features/department/departmentSlice";

const AddOrEditManagerModal = ({
  openModal,
  setOpenModal,
  selectData,
  setSelectData,
}) => {
  const isEditModal = selectData?.name === "manager" && openModal;

  const { managers: allManagers, manager: currentManager } = useSelector(
    (state) => state.managers
  );
  const { departments: allDepartments } = useSelector(
    (state) => state.departments
  );
  const { users: allUsers } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    manager: "",
    departments: [],
  });

  const [errors, setErrors] = useState({});

  const [selectedManager, setSelectedManager] = useState(null);

  useEffect(() => {
    if (isEditModal) {
      dispatch(getManager(selectData?.id));
    }
  }, [isEditModal]);

  useEffect(() => {
    if (isEditModal && currentManager?.manager?.name !== "") {
      setFormData({
        manager: currentManager?.manager,
        departments: currentManager?.departments,
      });
    }
  }, [currentManager]);

  const { manager, departments } = formData;

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal(false);
    setFormData({
      manager: "",
      departments: [],
    });
    setSelectData({ name: "", id: "" });
    setSelectedManager(null);
  };

  useEffect(() => {
    // Update selectedManager when manager or allUsers changes
    if (manager && allUsers.length > 0 && isEditModal) {
      const managerObject = allUsers.find((val) => val?._id === manager?._id);
      setSelectedManager(managerObject);
    }
  }, [manager, allUsers]);

  const onSubmit = (e) => {
    e.preventDefault();

    // Check for errors
    const formErrors = {};
    if (!manager) {
      formErrors.manager = "Manager is required.";
    }
    if (!departments.length) {
      formErrors.departments = "Departments are required.";
    }
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      dispatch(
        isEditModal
          ? updateManager({
              id: selectData.id,
              data: { manager, departments },
            })
          : createManager({
              manager,
              departments,
            })
      ).then((e) => {
        dispatch(getAllManagers());
        dispatch(getAllDepartments());
        setSelectData({ name: "", id: "" });
        handleClose();
      });
    }
  };

  const handleManagerChange = (e, value) => {
    setSelectedManager(value);
    if (!value) {
      setErrors({ ...errors, manager: "Manager is required." });
    } else {
      setErrors({ ...errors, manager: "" });
    }
    setFormData((prev) => ({
      ...prev,
      manager: value?._id,
    }));
  };

  const handleDepartmentsChange = (e, value) => {
    const selectedDepartments = value.map((option) => ({
      _id: option?._id,
    }));
    if (value?.length === 0) {
      setErrors({ ...errors, departments: "Departments are required." });
    } else {
      setErrors({ ...errors, departments: "" });
    }
    setFormData((prev) => ({
      ...prev,
      departments: selectedDepartments,
    }));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    boxShadow: 24,
    maxHeight: "92vh",
    overflowY: "auto",
    p: 2,
    borderRadius: 2,
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="mt-1">
          <h3 id="modal-modal-title" className="mb-4">
            {isEditModal ? "Edit" : "Add"} Manager
          </h3>

          <section className="w-auto">
            <form onSubmit={onSubmit}>
              <div className="form-group mb-2">
                <label htmlFor="project">
                  Manager
                  <span className="text-red-500">*</span>
                </label>
                <Autocomplete
                  options={allUsers}
                  value={selectedManager}
                  getOptionLabel={(option) => option?.name}
                  onChange={handleManagerChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="select manager"
                      error={errors?.manager} // Apply red border if there is an error
                    />
                  )}
                />
                {/* Error message */}
                {errors.manager && (
                  <div className="text-red-500 mb-2 !text-xs">
                    {errors.manager}
                  </div>
                )}
              </div>
              <div className="form-group mb-2">
                <label htmlFor="project">
                  Departments <span className="text-red-500">*</span>
                </label>
                <Autocomplete
                  multiple
                  options={allDepartments}
                  value={departments.map((department) =>
                    allDepartments.find((val) => val._id === department._id)
                  )}
                  getOptionLabel={(option) => option?.name}
                  onChange={handleDepartmentsChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="select departments"
                      error={errors?.departments}
                    />
                  )}
                />
                {errors.departments && (
                  <div className="text-red-500 mb-2 !text-xs">
                    {errors.departments}
                  </div>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  className="btn mb-4 border border-[#d0d5dd] text-primary w-40 h-10"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn mb-4 bg-primary text-white w-40 h-10"
                >
                  Continue
                </button>
              </div>
            </form>
          </section>
        </div>
      </Box>
    </Modal>
  );
};

export default AddOrEditManagerModal;
