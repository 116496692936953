import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API_BASE_URL);

const useSocket = (userDepartments) => {
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    socket.on('ticketCreated', (newTicket) => {
      // Check if newTicket.department is in the user's array of departments
      if (newTicket.department && userDepartments.includes(newTicket.department)) {
        setTicket(newTicket);
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off('ticketCreated');
    };
  }, [userDepartments]); // Dependency on userDepartments

  return ticket;
};

export default useSocket;
