import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTickets } from "../features/tickets/ticketSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../helpers/utils";
import { FaEye, FaSort, FaSortUp, FaSortDown, FaExpand, FaCompress } from "react-icons/fa";
import CreateTicketModal from "../components/CreateTicketModal";
import { getAllDepartments } from "../features/department/departmentSlice";
import useSocket from "../hooks/useSocket";
import AlertPopup from "../components/AlertPopup";
let audioInstance = null; // Singleton instance of the audio player

function Tickets() {
  const { tickets, isLoading } = useSelector((state) => state.tickets);
  const { user } = useSelector((state) => state.auth);
  const userDepartments = user?.departments || [];

  const ticket = useSocket(userDepartments);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (ticket) {
      const playAudioAndShowPopup = async () => {
        if (audioInstance) {
          audioInstance.pause();
          audioInstance.currentTime = 0;
        }

        audioInstance = new Audio('/assets/alert-sound.mp3');
        try {
          await audioInstance.play();
          setShowPopup(true);
        } catch (error) {
          console.error('Audio play failed:', error);
          setShowPopup(true);
        }
      };

      playAudioAndShowPopup();
    }
  }, [ticket]);

  const handleClosePopup = () => {
    setShowPopup(false);
    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0;
    }
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredTickets, setFilteredTickets] = useState(tickets?.tickets);
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [isClosedTickets, setIsClosedTickets] = useState(
    searchParams.get("isclosedtickets") === 'true'
  );

  const [sortConfig, setSortConfig] = useState({
    key: 'createdAt',
    direction: 'desc',
  });

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    dispatch(getTickets({ isClosedTickets }));
  }, [dispatch, isClosedTickets, ticket]);

  useEffect(() => {
    dispatch(getAllDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (isClosedTickets) {
      setFilteredTickets(
        tickets?.tickets?.filter((el) => el?.status?.toLowerCase() === "close")
      );
    } else {
      setFilteredTickets(
        tickets?.tickets?.filter((el) => el?.status?.toLowerCase() !== "close")
      );
    }
  }, [isClosedTickets, tickets]);

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      isclosedtickets: isClosedTickets,
    });
  }, [isClosedTickets]);

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: currentPage,
    });
  }, [currentPage]);

  const handlePageChange = (e, page) => {
    if (page === Number(currentPage) - 1) {
      return;
    }
    setCurrentPage(Number(page) + 1);
    dispatch(getTickets({ page: Number(page) + 1, isClosedTickets }));
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedTickets = () => {
    if (!filteredTickets) return [];

    return filteredTickets.slice().sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleCloseTickets = (val) => {
    setIsClosedTickets(val);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: 1,
      isclosedtickets: val
    });
    setCurrentPage(1);
  };

  const columns = [
    { id: "ticketNo", label: "Ticket ID", width: "100px" },
    { id: "isOnPriority", label: "Priority" },
    { id: "name", label: "Username", width: "160px" },
    { id: "project", label: "Project" },
    { id: "status", label: "Status" },
    { id: "createdAt", label: "Created on", width: "180px" },
    { id: "dueDate", label: "Due Date", width: "150px" },
    { id: "action", label: "Action" },
  ];

  const tableLoader = () => {
    return [...new Array(10)].map((_, i) => (
      <TableRow key={i}>
        {[...new Array(8)].map((_, i) => (
          <TableCell key={i}>
            <Skeleton variant="text" animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  return (
    <>
      {showPopup && (
        <AlertPopup handleClosePopup={handleClosePopup} />
      )}

      <style>
        {`
    @keyframes shuffling {
      0% { transform: scale(0.8); opacity: 0; }
      50% { transform: scale(1.05); opacity: 1; }
      100% { transform: scale(1); opacity: 1; }
    }
  `}
      </style>

      <h1 className="text-left text-lg mb-4 pl-2">Tickets</h1>
      <div className="flex justify-between items-center mb-3">
        <div className="flex items-center space-x-2">
          <button
            className="btn bg-primary text-white flex items-center"
            onClick={handleFullscreenToggle}
          >
            {isFullscreen ? <FaCompress /> : <FaExpand />}
            {/* <span className="ml-2">Fullscreen</span> */}
          </button>
          <RadioGroup
            row
            name="row-radio-buttons-group divide-x"
            value={isClosedTickets ? "closed" : "all"}
            className="border border-gray-300 rounded-xl pl-4"
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="All"
              checked={!isClosedTickets}
              onChange={() => handleCloseTickets(false)}
              className="border-r border-gray-300 pr-8"
            />
            <FormControlLabel
              value="closed"
              control={<Radio />}
              label="Closed"
              checked={isClosedTickets}
              onChange={() => handleCloseTickets(true)}
            />
          </RadioGroup>
        </div>
        <button
          className="btn bg-primary text-white"
          onClick={() => setOpenTicketModal(true)}
        >
          Create New Ticket
        </button>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column?.id}
                    style={{ width: column?.width, fontWeight: "600" }}
                  >
                    <div className="flex items-center">
                      {column.label}
                      {column.id === 'createdAt' || column.id === 'isOnPriority' ? (
                        <IconButton
                          size="small"
                          onClick={() => handleSort(column.id)}
                        >
                          {sortConfig.key === column.id ? (
                            sortConfig.direction === 'asc' ? (
                              <FaSortUp />
                            ) : (
                              <FaSortDown />
                            )
                          ) : (
                            <FaSort />
                          )}
                        </IconButton>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                tableLoader()
              ) : (
                <>
                  {sortedTickets()?.length ? (
                    sortedTickets()?.map((ticket) => (
                      <TableRow key={ticket?._id}>
                        <TableCell>
                          <span className="text-primary">
                            {ticket?.ticketNo}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="font-semibold">
                            {ticket?.isOnPriority ? "High" : "Low"}
                          </span>
                        </TableCell>
                        <TableCell>{ticket?.name}</TableCell>
                        <TableCell>{ticket?.project}</TableCell>
                        <TableCell style={{ minWidth: 140 }}>
                          <span className={`status status-${ticket?.status}`}>
                            {capitalizeFirstLetter(ticket?.status)}
                          </span>
                        </TableCell>
                        <TableCell>
                          {format(
                            new Date(ticket?.createdAt),
                            "MMM dd, yyyy h:mm a"
                          )}
                        </TableCell>
                        <TableCell>
                          {ticket?.dueDate
                            ? format(new Date(ticket?.dueDate), "MMM dd, yyyy")
                            : "-"}
                        </TableCell>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => navigate(`/ticket/${ticket?._id}`)}
                        >
                          <FaEye className="text-primary text-base" />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        No Tickets Available
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{
            paddingTop: 10,
            paddingBottom: 6,
          }}
          component="div"
          rowsPerPageOptions={[]}
          rowsPerPage={10}
          count={tickets?.count}
          page={Number(currentPage) - 1 || 0}
          onPageChange={(e, page) => handlePageChange(e, page)}
          showFirstButton={true}
          showLastButton={true}
          labelDisplayedRows={({ from, to, count }) => {
            return `Showing:\u00A0\u00A0 ${from}–${to} of ${count !== -1 ? count : `more than ${to}`
              }`;
          }}
        />
      </Paper>
      <CreateTicketModal
        openTicketModal={openTicketModal}
        setOpenTicketModal={setOpenTicketModal}
      />
    </>
  );
}

export default Tickets;
