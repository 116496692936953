import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/users`;

// Get all users
const getAllUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL, config); 
  return response.data;
}

// Register user
const register = async (userData) => {
  // userData is an object with an email and password i.e {name: 'Hina', email: 'hina@*****.com', password: '******'}
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    // localStorage can only hold strings
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Login user
const login = async (userData) => {
  // userData is an object with an email and password i.e {email: 'arif@*****.com', password: '******'}
  const response = await axios.post("https://support-api.dmlabs.in/api/users/microsoft-login", userData);

  if (response.data) {
    // localStorage can only hold strings
    // Save the user data to localStorage in a key called 'user'
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Login user
const resetPassword = async (userData) => {
  // userData is an object with an email and password i.e {email: 'arif@*****.com', password: '******'}
  const response = await axios.patch(API_URL + "/reset-password", userData);
  if (response.data) {
    // localStorage can only hold strings
    // Save the user data to localStorage in a key called 'user'
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

// Logout user
const logout = () => localStorage.clear();

const authService = {
  register,
  logout,
  login,
  resetPassword,
  getAllUsers
};

export default authService;
