import { Link, useNavigate } from 'react-router-dom';
import './index.css';

import logo from '../assets/dmllogo.svg';
import { Box, Icon, Menu, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MsAuthButton } from 'digi-oauth';
import { MdOutlineSettings } from 'react-icons/md';

function Header() {
  const navigate = useNavigate();
  const name = localStorage.getItem('name');

  const [profileClick, setProfileClick] = useState(null);
  const open = Boolean(profileClick);

  const handleLoginSuccess = async () => {
    localStorage.removeItem('user');
  };

  const keys = !!sessionStorage.getItem('msal.account.keys');

  React.useEffect(() => {
    if (!keys) {
      window.location.href = '/login';
      localStorage.clear();
    }
  }, [keys]);

  return (
    <header
      className="container"
      style={{ marginBottom: '0', paddingBottom: '0', padding: 0 }}
    >
      <div className="header h-16 flex mb-7 items-center py-5 justify-between">
        <div className="logo">
          <Link to="/">
            <div className="flex items-center">
              <img className="h-8 w-6 mr-1" src={logo} alt="" />
              <span className="text-[18px] font-bold">Ticketing</span>
            </div>
          </Link>
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MsAuthButton
            clientId={'f9621cd9-b735-4b1a-aa84-5f64d88b9ef1'}
            redirectUri={'/'}
            postLogoutRedirectUri={'/login'}
            tenantId={'71018f36-c0c7-4505-b7fe-3ca2f23ba679'}
            onLoginSuccess={handleLoginSuccess}
            onLogoutSuccess={() => window.location.reload()}
            popperClasses="bg-red-200"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontFamily: 'Inter', lineHeight: 1, mb: 0.25 }}
            >
              {name}
            </Typography>
            {/* <Typography variant='body2' sx={{ fontFamily: 'Inter', lineHeight: 1 }}>
              {email}
            </Typography> */}
          </Box>
          <Icon
            sx={{ ml: 1, cursor: 'pointer' }}
            onClick={() => navigate('/settings')}
          >
            <MdOutlineSettings />
          </Icon>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={profileClick}
          open={open}
          onClose={() => setProfileClick(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        ></Menu>
      </div>
    </header>
  );
}

export default Header;
