import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Backdrop,
  Box,
  Modal,
} from "@mui/material";

import {
  createDepartment,
  getAllDepartments,
  getDepartment,
  updateDepartment,
} from "../features/department/departmentSlice";

const AddOrEditDepartmentModal = ({
  openModal,
  setOpenModal,
  selectData,
  setSelectData,
}) => {
  const isEditModal = selectData?.name === "department" && openModal;


  const { department } = useSelector((state) => state.departments);

  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    if (isEditModal) {
      dispatch(getDepartment(selectData?.id));
    }
  }, [isEditModal]);

  useEffect(() => {
    if (isEditModal && department?.name !== "") {
      setFormData({
        name: department?.name,
        managers: department.managers,
      });
    }
  }, [department]);

  const { name } = formData;

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal(false);
    setFormData({
      name: "",
    });
    setSelectData({ name: "", id: "" });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name?.length)
      dispatch(
        isEditModal
          ? updateDepartment({
              id: selectData.id,
              data: { name },
            })
          : createDepartment({ name })
      ).then((e) => {
        dispatch(getAllDepartments());
      });
    setSelectData({ name: "", id: "" });
    handleClose();
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    boxShadow: 24,
    maxHeight: "92vh",
    overflowY: "auto",
    p: 2,
    borderRadius: 2,
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="mt-1">
          <h3 id="modal-modal-title" className="mb-4">
            {isEditModal ? "Edit" : "Add"} Department
          </h3>

          <section className="w-auto">
            <form onSubmit={onSubmit}>
              <div className="form-group mb-2">
                <label htmlFor="project">
                  Department Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="form-control p-2 focus:outline-none"
                  placeholder="Department Name"
                  name="name"
                  value={name}
                  onChange={onChange}
                  maxLength="40"
                  required
                />
              </div>

              {/* <div className="form-group mb-2">
                <label htmlFor="project">Managers</label>
                <Autocomplete
                  multiple
                  options={allManagers}
                  value={managers.map((manager) =>
                    allManagers.find((val) => val._id === manager._id)
                  )}
                  getOptionLabel={(option) => option?.firstName}
                  disableCloseOnSelect
                  onChange={handleManagersChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // variant="outlined"
                      placeholder="select managers"
                    />
                  )}
                  // renderOption={(props, option, { selected }) => (
                  //   <MenuItem
                  //     {...props}
                  //     key={option?._id}
                  //     value={option?._id}
                  //     sx={{ justifyContent: "space-between" }}
                  //   >
                  //     {option?.name}
                  //     {selected ? <FaCheck /> : null}
                  //   </MenuItem>
                  // )}
                />
              </div> */}
              <div className="flex justify-between">
                <button
                  className="btn mb-4 border border-[#d0d5dd] text-primary w-40 h-10"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn mb-4 bg-primary text-white w-40 h-10"
                >
                  Continue
                </button>
              </div>
            </form>
          </section>
        </div>
      </Box>
    </Modal>
  );
};

export default AddOrEditDepartmentModal;
