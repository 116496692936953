import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/managers/`;

//  Create new manager
const createManager = async (managerData, token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, managerData, config);
  return response.data;
};

// Get all managers
const getAllManagers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL, config);
  return response.data;
};

// Get a manager
const getManager = async (managerId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + managerId, config);
  return response.data;
};

// Delete manager
const deleteManager = async (managerId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + managerId, config);
  return response.data;
};

// update manager
const updateManager = async (managerData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL + managerData?.id,
    managerData?.data,
    config
  );

  return response.data;
};

const managerService = {
  createManager,
  getAllManagers,
  getManager,
  deleteManager,
  updateManager,
};

export default managerService;
