import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, reset } from '../features/auth/authSlice';
import './index.css';
import { Box, Card, Container } from '@mui/material';
import logo from '../assets/dmllogo.svg';
import loginImg from '../assets/persononcall.png';
import { MsAuthButton } from 'digi-oauth';

function Login() {
  const [loginTriggered] = useState(false); // Track if login is already triggered

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      window.location.href = '/tickets';
    }

    dispatch(reset());
  }, [isError, isSuccess, user, navigate, message, dispatch]);

  // Function to handle successful Microsoft login
  const handleLoginSuccess = useCallback(
    async (graphData, instance) => {
      dispatch(
        login({
          email: graphData.mail,
          name: graphData.displayName,
          role: 'user',
        })
      );
      return false;
    },
    [loginTriggered, navigate]
  );

  // Function to handle login errors
  const handleLoginError = (error) => {
    toast.error(`Login failed: ${error.message}`);
  };

  return (
    <div className="h-screen flex">
      <div className="w-1/2 h-full">
        <img src={loginImg} alt="img" className="w-full h-full object-cover" />
      </div>
      <div className="flex text-center w-1/2">
        <Container
          component="main"
          maxWidth="xs"
          sx={{ m: 'auto'}}
        >
          <div className="flex items-center justify-center mb-8">
            <img className={`w-[2.5rem] h-[2.5rem]`} src={logo} alt="logo" />
            <span className="header-text text-[16px] ml-1 font-bold"> Ticketing</span>
          </div>
          <Card
            variant="outlined"
            className="auth-card"
            sx={{ pb: 4 }}
          >
            <Box className="heading flex flex-col gap-2">
              <h1 className="text-xl">Login</h1>
              <p className="text-base text-grey">
                Please log in to get support
              </p>
            </Box>
            <Box
              sx={{
                mt: 3,
                // div: { 'width': '100%' },
                display: 'flex',
                justifyContent: 'center',
                button: { width: '100%', py: 1, px: 3 },
              }}
            >
              <MsAuthButton
                clientId={'f9621cd9-b735-4b1a-aa84-5f64d88b9ef1'}
                redirectUri={'/'}
                postLogoutRedirectUri={'/'}
                tenantId={'71018f36-c0c7-4505-b7fe-3ca2f23ba679'}
                onLoginSuccess={handleLoginSuccess}
                onLoginError={handleLoginError}
              />
            </Box>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default Login;
