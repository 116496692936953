import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import NewTicket from './pages/NewTicket';
import Register from './pages/Register';
import Tickets from './pages/Tickets';
import Ticket from './pages/Ticket';
import UserProfile from './pages/UserProfile';
import Settings from './pages/Settings';
import Layout from './components/Layout';
import { useSelector } from 'react-redux';
import useInactivityTimer from './hooks/useInactivityTimer';

function App() {
  // eslint-disable-next-line no-unused-vars
  const { user } = useSelector((state) => state.auth);
  // const { loggedIn, checkingStatus } = useAuthStatus();

  // console.log(user, loggedIn, checkingStatus);

  const handleSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login';
  };

  // Use the inactivity timer hook
  useInactivityTimer(32400000, handleSignOut); // 9 hrs

  return (
    <>
      <Router>
        <div>
          {/* {loggedIn && <Header />} */}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/tickets" />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/ticket/:ticketId" element={<Ticket />} />
              <Route path="/new-ticket" element={<NewTicket />} />
              <Route path="/test-path" element={<>Test</>} />
              <Route path="/settings" element={<Settings />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
