export const AlertPopup = ({ handleClosePopup }) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                backgroundColor: '#f44336',
                color: '#fff',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1000,
                animation: 'shuffling 1s ease-out',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <h2 style={{ margin: '0 0 10px', fontSize: '20px', fontWeight: 'bold' }}>
                Alert!! New Ticket Created
            </h2>
            <button
                onClick={handleClosePopup}
                style={{
                    backgroundColor: '#ffffff',
                    color: '#f44336',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    transition: 'background-color 0.3s ease',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#ffffff')}
            >
                Close
            </button>
        </div>
    );
};

export default AlertPopup;
